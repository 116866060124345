import React, { useEffect, useRef, useState } from 'react';
import '../css/Home.css';

const Home = () => {
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const learningTopics = [
    "Web Development",
    "Data Science",
    "AI & Machine Learning",
    "UI/UX Design",
    "Cloud Computing",
    "E-commerce Solutions"
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const firstColumn = firstColumnRef.current;
    const secondColumn = secondColumnRef.current;

    if (firstColumn && secondColumn) {
      const animateScroll = () => {
        if (isMobile) {
          if (firstColumn.scrollLeft >= firstColumn.scrollWidth / 2) {
            firstColumn.scrollLeft = 0;
          } else {
            firstColumn.scrollLeft += 1;
          }

          if (secondColumn.scrollLeft <= 0) {
            secondColumn.scrollLeft = secondColumn.scrollWidth / 2;
          } else {
            secondColumn.scrollLeft -= 1;
          }
        } else {
          if (firstColumn.scrollTop >= firstColumn.scrollHeight / 2) {
            firstColumn.scrollTop = 0;
          } else {
            firstColumn.scrollTop += 1;
          }

          if (secondColumn.scrollTop <= 0) {
            secondColumn.scrollTop = secondColumn.scrollHeight / 2;
          } else {
            secondColumn.scrollTop -= 1;
          }
        }

        requestAnimationFrame(animateScroll);
      };

      requestAnimationFrame(animateScroll);
    }
  }, [isMobile]);

  return (
    <>
      <div className='home'>
        <div className="left center">
          <h1 className='big-h'>Master new skills with <span>devs</span><span>aura</span><span>.</span></h1>
          <p>Get in touch with us about the skills you're eager to learn. We are here to help you master the topics you're most passionate about and guide you through the learning process effectively. Contact us or email us at <a href="mailto:academy@devsaura.com">academy@devsaura.com</a> with the topics you want to focus on, and we'll tailor the experience to your needs.</p>
        </div>
        <div className={`right ${isMobile ? 'mobile' : ''}`}>
          <div className="scroll-column first" ref={firstColumnRef}>
            {[...learningTopics, ...learningTopics].map((topic, index) => (
              <div key={`first-${index}`} className="item center">
                <p>{topic}</p>
              </div>
            ))}
          </div>
          <div className="scroll-column second" ref={secondColumnRef}>
            {[...learningTopics, ...learningTopics].map((topic, index) => (
              <div key={`second-${index}`} className="item center">
                <p>{topic}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="contact-btn">
        <a href='https://devsaura.com/contact-us' target='_blank' rel='noreferrer noopener'>Contact Now and Start Learning</a>
      </div>
    </>
  );
};

export default Home;
